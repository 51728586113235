import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuthContext } from './useAuthContext';
import PermissionDenied from '../components/permission-denied/PermissionDenied';
export default function RoleBasedGuard({ hasContent, roles, children, isProfilePage, }) {
    const { user } = useAuthContext();
    const hasSuperAdminRole = user?.roles.find((role) => role?.code === 'SUPER_ADMIN');
    const currentRole = hasSuperAdminRole ? 'SUPER_ADMIN' : user?.roles[0].code;
    if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
        return hasContent ? _jsx(PermissionDenied, {}) : null;
    }
    return _jsxs(_Fragment, { children: [" ", children, " "] });
}
