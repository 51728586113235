import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
// components
import { useAuthContext } from './useAuthContext';
export default function PasswordCheckGuard({ children }) {
    const { user, isAuthenticated } = useAuthContext();
    if (isAuthenticated && user?.changedPassword === false) {
        // Redirect to the UpdatePassword page
        return _jsx(Navigate, { to: "/update-password", replace: true });
    }
    return _jsx(_Fragment, { children: children });
}
